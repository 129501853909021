* {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Yun", sans-serif;
}

@font-face {
  font-family: "Yun";
  src: url("/src/assets/fonts/윤고딕110.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "210M";
  src: url("/src/assets/fonts/210M고딕100.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

.Mid_Main {
  padding: 12%;
}

@media (prefers-reduced-motion: no-preference) {
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .Mid_Main .main {
    margin-top: 20%;
    width: 99%;
  }
}

/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .Mid_Main .main {
    margin-top: 20%;
    width: 99%;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 480px) and (max-width: 767px) {
  .Mid_Main .main {
    margin-top: 20%;
    width: 99%;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  .Mid_Main .main {
    margin-top: 20%;
    width: 99%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
